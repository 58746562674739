<template>
    <div>
        <div class="animate-act-part">
            <div class="flex map-animate">
                <div
                    class="animate-item flex-both-center"
                    :class="[animateNum == index ? 'animate-active' : '']"
                    v-for="(item, index) in animateList"
                    :key="index"
                    @click="getMapAnimate(item, index)"
                >
                    {{ item.name }}
                </div>
            </div>
            <!-- 闪烁 -->
            <div class="twinkle-part flex-y-center" v-if="animateNum == 0">
                <i
                    class="icon"
                    :class="[
                        twinklePart.state
                            ? 'el-icon-video-pause'
                            : 'el-icon-video-play',
                    ]"
                    @click="changeTwinkleState()"
                ></i>
                <div class="select-part">
                    <el-select
                        style="width: 160px"
                        v-model="twinklePart.result"
                        size="mini"
                        :clearable="false"
                        @change="mapTwinkleAct()"
                        popper-class=""
                    >
                        <el-option
                            v-for="(item, index) in twinklePart.list"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 透明度 -->
            <div class="opacity-part flex-y-center" v-if="animateNum == 1">
                <el-slider
                    style="width: 100%"
                    v-model="opacityValue"
                    :format-tooltip="formatTooltip"
                    @input="changOpacity"
                ></el-slider>
            </div>
        </div>
    </div>
</template>
<script>
import Draggabilly from "draggabilly";
export default {
    name: "",
    data() {
        return {
            animateNum: 2, //0闪烁 1 透明 2联动对比
            animateList: [
                {
                    name: "闪烁",
                },
                {
                    name: "透明",
                },
                {
                    name: "联动对比",
                },
                {
                    name: "卷帘对比",
                },
            ],
            // 闪烁部分
            twinklePart: {
                timer: "",
                state: false,
                result: 1000,
                list: [
                    {
                        id: 500,
                        name: "0.5s",
                    },
                    {
                        id: 1000,
                        name: "1s",
                    },
                    {
                        id: 2000,
                        name: "2s",
                    },
                ],
            },
            opacityValue: 100, //透明度
            sliderValue: 0, //卷帘对比
            sliderSum: 0,
        };
    },
    props: {
        left: {
            type: Object,
            default() {
                return {};
            },
        },
        leftLayerGroup: {
            type: Object,
            default() {
                return {};
            },
        },
        leftLayer: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {},
    mounted() {},
    destroyed() {},
    methods: {
        // 闪烁 透明 联动对比 卷帘对比 切换
        getMapAnimate(item, index) {
            if (item.name == "联动对比") {
                this.$emit("openLddb");
            } else {
                this.$emit("closeLddb");
            }

            this.animateNum = index;
            this.left._onResize();
            // 停止闪烁
            if (this.twinklePart.timer) {
                clearInterval(this.twinklePart.timer);
            }
            this.twinklePart.state = false;
            this.leftLayerGroup.eachLayer((item) => {
                item.setOpacity(1);
            });
            // 显示图层
            this.opacityValue = 100;
            this.changOpacity();
            // 卷帘对比
            if (item.name == "卷帘对比") {
                this.$emit("openJldb");
                this.initRoll();
            } else {
                this.$emit("closeJldb");
                this.resetRoll();
            }
        },
        // 更改闪烁状态
        changeTwinkleState() {
            this.twinklePart.state = !this.twinklePart.state;
            if (this.twinklePart.state) {
                this.mapTwinkleAct();
            } else {
                clearInterval(this.twinklePart.timer);
                this.leftLayerGroup.eachLayer((item) => {
                    item.setOpacity(1);
                });
            }
        },
        // 左侧地图不断的闪烁
        mapTwinkleAct() {
            let opacity = 1;
            if (this.twinklePart.timer) {
                clearInterval(this.twinklePart.timer);
            }
            if (this.twinklePart.state) {
                this.twinklePart.timer = setInterval(() => {
                    opacity = opacity === 0 ? 1 : 0;
                    this.leftLayerGroup.eachLayer((item) => {
                        item.setOpacity(opacity);
                    });
                }, this.twinklePart.result);
            }
        },
        // 改变滑块提示信息数值
        formatTooltip(val) {
            return val;
        },
        // 改变透明度
        changOpacity() {
            let opacity = this.opacityValue / 100;
            if (this.leftLayerGroup) {
                this.leftLayerGroup.eachLayer((item) => {
                    item.setOpacity(opacity);
                });
            }
        },
        // 初始化卷帘
        initRoll() {
            let elem2 = document.querySelector(".roller-shutter");
            let draggie2 = new Draggabilly(elem2, {
                axis: "x",
            });
            elem2.style.left = $(".left-part").width() / 2 - 14 + "px";
            draggie2.on("dragMove", (event, pointer, moveVector) => {
                let value =
                    ((draggie2.position.x + 14) / $(".left-part").width()) *
                    100;
                this.sliderValue = value;
                this.sliderSum = draggie2.position.x + 14;
                this.rollingCompare();
            });
            this.startRoll();
        },
        // 开始卷帘
        startRoll() {
            this.sliderValue = 0;
            this.sliderSum = $(".left-part").width() / 2;
            let elem2 = document.querySelector(".roller-shutter");
            elem2.style.left = $(".left-part").width() / 2 - 14 + "px";
            this.rollingCompare();
        },
        // 卷帘对比方法，修改影像图层的边界大小
        rollingCompare() {
            let step = this.sliderValue;
            let nw = this.left.containerPointToLayerPoint([0, 0]);
            let se = this.left.containerPointToLayerPoint(this.left.getSize());
            let clipX = nw.x + ((se.x - nw.x) * step) / 100;
            let rect = "rect(" + [nw.y, clipX, se.y, nw.x].join("px,") + "px)";
            this.leftLayer.getContainer().style.clip = rect;
            $(".splitLine").css("left", this.sliderSum + "px");
        },
        // 结束卷帘
        resetRoll() {
            let nw = this.left.containerPointToLayerPoint([0, 0]);
            let se = this.left.containerPointToLayerPoint(this.left.getSize());
            let rect = "rect(" + [nw.y, se.x, se.y, nw.x].join("px,") + "px)";
            this.leftLayer.getContainer().style.clip = rect;
        },
    },
};
</script>
<style lang="scss" scoped>
.animate-act-part {
    .map-animate {
        height: 32px;
        background: #fbfbfc;
        border-radius: 4px;
        .animate-item {
            color: rgba(154, 174, 187, 1);
            font-size: 14px;
            padding: 0 4px;
            cursor: pointer;
            font-weight: bold;
        }
        .animate-active {
            color: rgba(81, 103, 116, 1);
        }
    }
    .twinkle-part {
        background: #fff;
        margin-top: 10px;
        padding: 4px 6px;
        border-radius: 5px;
        .icon {
            font-size: 24px;
            color: #4d90e3;
            margin-right: 4px;
            cursor: pointer;
        }
        .select-part {
            width: 160px;
            height: 32px;
            position: relative;
            color: #516774;
        }
    }
    .opacity-part {
        background: #fff;
        margin-top: 10px;
        padding: 4px 6px;
        border-radius: 5px;
        width: 205px;
    }
}
</style>
