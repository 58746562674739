<template>
    <div class="map-act-module">
        <div class="mapOperate">
            <div class="operateItem" title="全屏" @click="changeFullScreen()">
                <i class="el-icon-full-screen"></i>
            </div>
            <div
                class="operateItem fa fa-th-list"
                title="图斑列表"
                @click="openSpotList()"
            ></div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
    methods: {
        changeFullScreen() {
            this.common.fullScreen();
        },
        openSpotList() {
            this.$emit("openSpotList");
        },
    },
};
</script>
<style lang="scss" scoped>
.map-act-module {
    .operateItem {
        width: 30px;
        height: 30px;
        background: #fff;
        text-align: center;
        cursor: pointer;
        line-height: 24px;
    }
    .operateItem {
        &:nth-child(1) {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &:nth-last-child(1) {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
    .fullScreen {
        position: absolute;
        top: 200px;
        left: 20px;
        z-index: 1000;
        cursor: pointer;
    }
}
</style>
