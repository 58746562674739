<template>
    <div class="spot-detail ">
        <!--图斑表单 -->
        <div class="marker-content">
            <el-form
                ref="form"
                :model="formData"
                label-width="160px"
                :inline="true"
            >
                <!-- <el-row style="border-bottom: 1px solid #ddd"> -->
                <el-form-item label="图斑编号:">
                    <el-input
                        size="mini"
                        @input="toNumber"
                        v-model="formData.TBBH"
                    ></el-input>
                </el-form-item>
                <el-form-item label="图斑名称:">
                    <el-input size="mini" v-model="formData.TBNAME"></el-input>
                </el-form-item>
                <el-form-item label="土地类型:">
                    <input
                        size="mini"
                        id="land-types1"
                        v-model="formData.TDFL"
                        type="text"
                        class="landType"
                        readonly
                        placeholder="变化前"
                        @click="showLandType(1)"
                    />
                    --
                    <ul
                        v-show="showLandTypes"
                        class="land-types-father scrollbar"
                    >
                        <li
                            v-for="(val, index) in landTypes"
                            :key="index"
                            @click="getLandFatherType(index, val, $event, 1)"
                        >
                            {{ val.name }}
                            <span
                                v-if="index !== landTypeIdx"
                                class="fa fa-plus-square"
                                @click="openLandType(1, index, $event)"
                            ></span>
                            <span
                                v-if="index === landTypeIdx"
                                class="fa fa-minus-square"
                                @click="openLandType(1, index, $event)"
                            ></span>
                            <ul
                                v-show="index === landTypeIdx"
                                class="land-types-children"
                            >
                                <li
                                    v-for="(val1, idx) in val.children"
                                    :key="idx"
                                    @click="getLandType(1, val1)"
                                >
                                    {{ val1 }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <input
                        size="mini"
                        id="land-types2"
                        v-model="formData.TDFLBHH"
                        type="text"
                        class="landType"
                        readonly
                        placeholder="变化后"
                        @click="showLandType(2)"
                    />
                    <ul
                        v-show="showLandTypes2"
                        class="
                                        land-types-father
                                        land-types-father-after
                                        scrollbar
                                    "
                    >
                        <li
                            v-for="(val, index) in landTypes"
                            :key="index"
                            @click="getLandFatherType(index, val, $event, 2)"
                        >
                            {{ val.name }}
                            <span
                                v-if="index !== landTypeIdx2"
                                class="fa fa-plus-square"
                                @click="openLandType(2, index, $event)"
                            ></span>
                            <span
                                v-if="index === landTypeIdx2"
                                class="fa fa-minus-square"
                                @click="openLandType(2, index, $event)"
                            ></span>
                            <ul
                                v-show="index === landTypeIdx2"
                                class="land-types-children"
                            >
                                <li
                                    v-for="(val1, idx) in val.children"
                                    :key="idx"
                                    @click="getLandType(2, val1)"
                                >
                                    {{ val1 }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="图斑面积:">
                    <el-input
                        size="mini"
                        v-model="formData.MJ"
                        @input="
                            formData.MJ = (formData.MJ.match(
                                /\d+(\.\d{0,})?/
                            ) || [''])[0]
                        "
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="图斑描述:">
                    <el-input
                        size="mini"
                        v-model="formData.TBDESC"
                        class="row-font"
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="请输入内容"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="是否为格局变化:" class="font-sline">
                    <el-select size="mini" v-model="formData.ZTGJSFBH">
                        <el-option value="1" label="是"></el-option>
                        <el-option value="0" label="否"></el-option>
                    </el-select>
                </el-form-item>
                <div class="line"></div>
                <el-form-item label="是否为新建涉建项目:" class="font-sline">
                    <el-select size="mini" v-model="formData.SFXJSJXM">
                        <el-option value="是"></el-option>
                        <el-option value="否"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="formData.SFXJSJXM === '是'"
                    label="新建涉建项目:"
                >
                    <el-select
                        size="mini"
                        v-model="formData.SDLX"
                        placeholder="请选择新建涉建项目"
                    >
                        <el-option value="新建"></el-option>
                        <el-option value="改扩建/重建"></el-option>
                        <el-option value="情况不符"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称:">
                    <el-input size="mini" v-model="formData.XMMC"></el-input>
                </el-form-item>
                <el-form-item label="建筑用途:">
                    <el-select
                        size="mini"
                        v-model="formData.JZYT"
                        placeholder="请选择建筑用途"
                    >
                        <el-option value="居住建筑"></el-option>
                        <el-option value="行政办公"></el-option>
                        <el-option value="文教建筑"></el-option>
                        <el-option value="科研建筑"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目建设状态:">
                    <el-select
                        size="mini"
                        v-model="formData.XMJSZT"
                        placeholder="请选择项目建设状态"
                    >
                        <el-option value="建设中"></el-option>
                        <el-option value="竣工"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否征得文物部门同意:">
                    <el-select size="mini" v-model="formData.SFZDWWBMTY">
                        <el-option value="是"></el-option>
                        <el-option value="否"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" ">
                    <div style="width:230px">
                        <sn-upload
                            ref="fileUpload"
                            savePath="spot_info"
                            basicType="documentSpecial"
                            listType="text"
                            :tipOption="tipOption"
                            :limit="9999"
                            :maxSize="null"
                            :fileExist="fileExist"
                            btnStyle="btn-2"
                            @getFileList="getFileList"
                        ></sn-upload>
                    </div>
                </el-form-item>
                <el-form-item label="开工时间:">
                    <el-date-picker
                        size="mini"
                        v-model="formData.KGSJ"
                        value-format="yyyy-MM-dd"
                        type="date"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="竣工时间:">
                    <el-date-picker
                        size="mini"
                        v-model="formData.JGSJ"
                        value-format="yyyy-MM-dd"
                        type="date"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="建筑面积:">
                    <el-input
                        size="mini"
                        v-model="formData.JZMJ"
                        @input="
                            formData.JZMJ = (formData.JZMJ.match(
                                /\d+(\.\d{0,})?/
                            ) || [''])[0]
                        "
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="高度:">
                    <el-input
                        size="mini"
                        v-model="formData.GD"
                        placeholder="保留1位小数"
                        @input="
                            formData.GD = (formData.GD.match(
                                /\d+(\.\d{0,1})?/
                            ) || [''])[0]
                        "
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="层数:">
                    <el-input
                        size="mini"
                        v-model="formData.CS"
                        @input="
                            formData.CS = (formData.CS.match(
                                /\d+(\.\d{0,})?/
                            ) || [''])[0]
                        "
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否符合管理规定:" class="font-sline">
                    <el-select size="mini" v-model="formData.SFFHGLGD">
                        <el-option value="是"></el-option>
                        <el-option value="否"></el-option>
                        <el-option value="无法定保护规划"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="影响评估:">
                    <el-select
                        size="mini"
                        v-model="formData.PG"
                        placeholder="请选择评估项"
                    >
                        <el-option
                            v-for="item in TBPGs"
                            :key="item.VALUE"
                            :label="item.VALUE"
                            :value="item.TYPE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <div
                    class="flex-end"
                    v-show="formData.SHZT !== '0'"
                    style="padding:10px;"
                >
                    <div class="flex-end">
                        <!-- 这里不需要审核了 -->
                        <!-- <div class="actBtn" @click="savePass" v-if="isCheck">
                            审核通过
                        </div>
                        <div class="actBtn" @click="saveNoPass" v-if="isCheck">
                            审核不通过
                        </div> -->
                        <div class="actBtn" @click="saveNosubmit" v-if="isEdit">
                            保存暂不提交
                        </div>
                        <div class="actBtn" @click="saveSubmit" v-if="isEdit">
                            保存并提交
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { commonMethods } from "@views/working_desk/monitoring_data/table_config/table-common-methods";
import { mapGetters, mapActions } from "vuex";
const SHZT = window.SHZT;
import snUpload from "./sn_upload";
export default {
    name: "",
    components: {
        snUpload,
    },
    data() {
        return {
            commonMethods,
            BASE_URL: window.REQUEST_URL,
            TBPGs: [
                {
                    VALUE: "正面影响",
                    TYPE: "0",
                    COLOR: "#A7C49B",
                },
                {
                    VALUE: "兼有正、负影响",
                    TYPE: "2",
                    COLOR: "#D7C49D",
                },
                {
                    VALUE: "负面影响",
                    TYPE: "1",
                    COLOR: "#CD7177",
                },
                {
                    VALUE: "目前尚未确定",
                    TYPE: "3",
                    COLOR: "#B09298",
                },
            ],
            formData: {
                GEOJSON: null,
                YEAR1: null,
                YEAR2: null,
                TBBH: null,
                TBNAME: null,
                TBDESC: null,
                MJ: null,
                TDFL: null,
                TDFLBHH: null,
                PG: null,
                SJWHYCBM: null,
                ZTGJSFBH: null,
                SFXJSJXM: null,
                SDLX: null,
                XMMC: null,
                JZYT: null,
                XMJSZT: null,
                SFZDWWBMTY: null,
                KGSJ: null,
                JGSJ: null,
                JZMJ: null,
                GD: null,
                CS: null,
                SFFHGLGD: null,
                SHZT: "0",
            },
            guidList: [],
            showLandTypes: false,
            showLandTypes2: false,
            landTypes: [
                { name: "耕地", children: ["水田", "水浇地", "旱地"] },
                { name: "园地", children: ["果园", "茶园", "其他园地"] },
                { name: "林地", children: ["有林地", "灌木林地", "其他林地"] },
                {
                    name: "草地",
                    children: ["天然牧草地", "人工牧草地", "其他草地"],
                },
                {
                    name: "商服用地",
                    children: [
                        "批发零售用地",
                        "住宿餐饮用地",
                        "商务金融用地",
                        "其他商服用地",
                    ],
                },
                {
                    name: "工矿仓储用地",
                    children: ["工业用地", "采矿用地", "仓储用地"],
                },
                { name: "住宅用地", children: ["城镇住宅用地", "农村宅基地"] },
                {
                    name: "公共管理与公共服务用地",
                    children: [
                        "机关团体用地",
                        "新闻出版用地",
                        "科教用地",
                        "医卫慈善用地",
                        "文体娱乐用地",
                        "公共设施用地",
                        "公园与绿地",
                        "风景名胜设施用地",
                    ],
                },
                {
                    name: "特殊用地",
                    children: [
                        "军事设施用地",
                        "使领馆用地",
                        "监教场所用地",
                        "宗教用地",
                        "殡葬用地",
                    ],
                },
                {
                    name: "交通运输用地",
                    children: [
                        "铁路用地",
                        "公路用地",
                        "街巷用地",
                        "农村道路",
                        "机场用地",
                        "港口码头用地",
                        "管道运输用地",
                    ],
                },
                {
                    name: "水域及水利设施用地",
                    children: [
                        "河流水面",
                        "湖泊水面",
                        "水库水面",
                        "坑塘水面",
                        "沿海滩涂",
                        "内陆滩涂",
                        "沟渠",
                        "水工建筑用地",
                        "冰川及永久积雪",
                    ],
                },
                {
                    name: "其他土地",
                    children: [
                        "空闲地",
                        "设施农业用地",
                        "田坎",
                        "盐碱地",
                        "沼泽地",
                        "沙地",
                        "裸地",
                    ],
                },
            ],
            landTypeIdx: 0, // 土地类型变化前
            landTypeIdx2: 0, // 土地类型变化后
            isCheck: true,
            isEdit: true,
            // 上传文字提示
            tipOption: {
                state: true,
                color: "#606266",
                labelState: false,
                maxState: false,
            },
            // 已经上传的文字
            fileExist: {
                list: [],
                name: "WJMC",
                url: "WJLJ",
            },
        };
    },
    props: {
        rightYear: {
            type: String,
            default() {
                return "2021";
            },
        },
        leftYear: {
            type: String,
            default() {
                return "2022";
            },
        },
        detail: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        ...mapGetters(["heritageId", "userId"]),
    },
    watch: {
        detail: {
            deep: true,
            immediate: true,
            handler() {
                if (this.detail) {
                    // SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
                    this.getSpotDetail();
                }
            },
        },
    },
    mounted() {
        this.type = this.$route.query.type ? this.$route.query.type : "";
        this.contralBtn();
    },
    methods: {
        ...mapActions([
            "GetSensing",
            "UpdateSensing", // 图斑修改保存
            "updateShztOfMonitorData",
        ]),
        contralBtn() {
            // type: 0 => 新建;      1 => 编辑;      2 => 查看;      3=> 审核
            // btnType: 0 查看  1 编辑  2 审核
            let btnType =
                this.type === "0" || this.type === "1" || this.type === ""
                    ? "1"
                    : this.type === "3"
                    ? "2"
                    : "0";
            this.isEdit = btnType === "1";
            // this.isCheck = this.commonMethods.isPower("2") && btnType === "2"; // 有审核权限并且点击审核按钮进入
        },
        // 获取土地类型父级内容
        getLandFatherType: function(idx, val, e, type) {
            let c;
            let s;
            if (type === 1) {
                c = "TDFL";
                s = "showLandTypes";
            }
            if (type === 2) {
                c = "TDFLBHH";
                s = "showLandTypes2";
            }
            if (e.target.tagName === "SPAN") {
                return;
            }
            if ($(e.target).parent()[0].className != "land-types-children") {
                this.formData[c] = val.name;
                this[s] = false;
            }
        },
        // 获取土地类型
        getLandType: function(type, da) {
            let c;
            let s;
            if (type === 1) {
                c = "TDFL";
                s = "showLandTypes";
            }
            if (type === 2) {
                c = "TDFLBHH";
                s = "showLandTypes2";
            }
            this.formData[c] = da;
            this[s] = false;
        },
        // 审核通过
        savePass() {
            this.saveSHZT(2);
        },
        // 审核不通过
        saveNoPass() {
            this.saveSHZT(3);
        },
        saveSHZT(zt) {
            this.$confirm("审核提交后不能更改，你确认要提交吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    if (Number(zt) === Number(SHZT.AUDIT_NOT_PASSED)) {
                        this.$prompt("请输入未审核通过原因", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        })
                            .then(({ value }) => {
                                if (value == null || value.trim().length == 0) {
                                    this.common.showMsg(
                                        "输入无效,请重新审核",
                                        "info"
                                    );
                                } else {
                                    this.updateSjzt(zt, value);
                                }
                            })
                            .catch(() => {
                                this.common.showMsg("已取消", "info");
                            });
                    } else {
                        this.updateSjzt(zt);
                    }
                })
                .catch((e) => {
                    this.common.showMsg("已取消审核", "info");
                });
        },
        async updateSjzt(zt, reason) {
            let res = await this.updateShztOfMonitorData({
                itemid: 90305,
                sjid: this.formData.ID || this.$route.query.id,
                shzt: zt,
                shrid: this.userId || localStorage.getItem("userId"),
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
            });
            if (res.IsSuccess) {
                this.common.showMsg("审核成功", "success");
                this.closeDialog();
            } else {
                this.$alert(res.ResultValue);
            }
        },
        // 保存暂不提交
        saveNosubmit() {
            this.formData.SHZT = "1";
            this.saveTbData();
        },
        // 保存并提交
        saveSubmit() {
            this.formData.SHZT = "0";
            this.saveTbData();
        },
        async saveTbData() {
            this.formData.USERID =
                this.userId || localStorage.getItem("userId");
            let ent = this.formData; // 图斑表信息
            // 修改-保存图斑信息
            let res = await this.UpdateSensing({
                layerId: this.formData.ID,
                ent,
                fjids: this.guidList.join(),
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
            this.closeDialog();
        },
        // 关闭图斑详细信息模态框
        closeDialog() {
            this.$emit("closeDialog");
        },
        // 获取 上传附件 图片
        getFileList(list) {
            this.guidList = [];
            list.map((item) => {
                this.guidList.push(item.Guid);
            });
        },
        // 获取图斑详细信息
        async getSpotDetail() {
            let res = await this.GetSensing({ id: this.detail.ID });
            if (res.IsSuccess) {
                let da = res.ResultValue;
                this.formData = da.TBXX[0];
                this.fileExist.list = da.FJ;
                this.guidList = [];
                da.FJ.map((item) => {
                    this.guidList.push(item.Guid);
                });
            }
        },
        showLandType: function(da) {
            //设置土地类型下拉框显示问题
            if (da == 1) {
                this.showLandTypes = !this.showLandTypes;
                this.showLandTypes2 = false;
            }
            if (da == 2) {
                this.showLandTypes2 = !this.showLandTypes2;
                this.showLandTypes = false;
            }
        },
        openLandType: function(type, idx, e) {
            // 打开土地类型树形结构
            let c;
            if (type == 1) {
                c = "landTypeIdx";
            }
            if (type == 2) {
                c = "landTypeIdx2";
            }
            if (e.target.className == "fa fa-plus-square") {
                this[c] = idx;
            } else {
                this[c] = -1;
            }
        },
        toNumber() {
            if (this.formData.TBBH) {
                // int 无特殊值
                this.formData.TBBH = this.formData.TBBH.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
                let reg = new RegExp("^(\\d{0," + 8 + "}).*$");
                this.formData.TBBH = this.formData.TBBH.replace(reg, "$1");
                if (this.formData.TBBH != "") {
                    // 整数过滤 整数不允许输入多个0
                    this.formData.TBBH = parseFloat(this.formData.TBBH);
                } else {
                    this.formData.TBBH = "";
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.spot-detail {
    height: 100%;
    width: 100%;
    position: relative;
    .marker-content {
        width: 100%;
        .el-input,
        .el-textarea {
            width: 230px;
        }
        .el-select {
            width: 230px;
        }
        label {
            padding-right: 0px;
            padding-left: 20px;
        }
        .landType {
            width: 104px;
            border: 1px solid #bfcbd9;
            height: 30px;
            padding-left: 5px;
            color: #606266;
        }
        .line {
            border: 1px solid #ddd;
        }
    }

    .land-types-father {
        position: absolute;
        top: 40px;
        height: 197px;
        overflow-y: auto;
        border: 1px solid #ccc;
        z-index: 1;
        background: #fff;
        padding: 5px 12px 5px 30px;
        width: 140px;
    }
    .land-types-father-after {
        left: 130px;
    }
    .land-types-father li {
        cursor: pointer;
        text-align: left;
        line-height: 20px;
        &:hover {
            color: #23468c;
        }
    }

    .land-types-father > li {
        padding: 3px 0;
        border-bottom: 1px dotted #ccc;
        position: relative;
    }
    .land-types-father > li:last-child {
        border-bottom: none;
    }
    .land-types-children {
        margin-left: 1em;
    }
    .land-types-children li {
        padding: 3px 0;
    }
    .land-types-father .fa {
        position: absolute;
        left: -22px;
        top: 5px;
    }
    .el-row {
        padding-top: 20px;
    }
}

.speci_style {
    display: inline-block;
    width: 100%;
    .el-upload-list__item {
        line-height: 2;
        text-align: left;
    }
    .el-upload-list__item:first-child {
        margin: 0;
    }
}

.el-upload-list__item-name:visited {
    color: #606266;
}
.actBtn {
    height: 30px;
    line-height: 30px;
    background: #186d70;
    color: #fff;
    border-color: #186d70;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    padding: 0 10px;
    margin-right: 10px;
}
</style>
