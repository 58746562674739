<template>
    <div class="remote-module">
        <div v-show="!spotListState" class="remote-map flex">
            <div class="left-part flex-1">
                <baseMap
                    id="left"
                    :myMap.sync="left"
                    :mapData="mapData"
                    mapid="left"
                >
                </baseMap>
                <!-- 图层按钮 -->
                <div class="layer-part">
                    <changeBtn ref="changeBtn"> </changeBtn>
                </div>
                <!-- 地图动画 -->
                <div class="animate-part">
                    <animate-act
                        :left="left"
                        :leftLayerGroup="layerList[0].gqyxGroup"
                        :leftLayer="leftLayer"
                        @openLddb="openLddb()"
                        @closeLddb="closeLddb()"
                        @openJldb="openJldb()"
                        @closeJldb="closeJldb()"
                    ></animate-act>
                </div>
                <!-- 分割线 -->
                <div v-show="jldbState" class="splitLine"></div>
                <i v-show="jldbState" class="el-icon-rank roller-shutter"></i>
                <el-button class="btn-back" size="mini" @click="showTbList">
                    返回列表
                </el-button>
                <el-select
                    size="mini"
                    v-model="leftYear"
                    placeholder="请选择"
                    class="select-left"
                >
                    <el-option
                        v-for="item in [layerList[0]]"
                        :key="item.name"
                        :label="item.name"
                        :value="item.year"
                    >
                    </el-option>
                </el-select>
                <!-- 地图操作 -->
                <div class="mapOperate">
                    <mapAct @openSpotList="spotListState = true"></mapAct>
                </div>
                <map-draw
                    v-if="type != '2'"
                    :basicMap="left"
                    :drawConf="drawConf"
                    @updateDraw="updateDraw"
                >
                </map-draw>
            </div>
            <div class="right-part" v-show="lddbState">
                <baseMap
                    id="right"
                    :myMap.sync="right"
                    :mapData="mapData"
                    mapid="right"
                >
                </baseMap>
                <el-select
                    size="mini"
                    v-model="rightYear"
                    placeholder="请选择"
                    class="select-right"
                >
                    <el-option
                        v-for="item in [layerList[1]]"
                        :key="item.name"
                        :label="item.name"
                        :value="item.year"
                    >
                    </el-option>
                </el-select>
            </div>

            <!--图斑详情 -->
            <div v-show="spotPart.dialog" class="spot-module draggable">
                <h5 class="handle flex-between">
                    <div>
                        图斑信息
                    </div>
                    <i class="el-icon-close" @click="closeSpotDetail()"></i>
                </h5>
                <spot-detail
                    v-if="spotPart.dialog"
                    :rightYear="rightYear"
                    :leftYear="leftYear"
                    :detail="spotPart.detail"
                    :spotPart="spotPart"
                    @closeDialog="closeSpotDetail"
                ></spot-detail>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legend-part" v-if="!spotListState">
            <legend-list></legend-list>
        </div>
        <!-- 图斑列表 -->
        <div v-if="spotListState" class="tb-list">
            <tableList
                @selectLayer="selectLayer"
                @close="spotListState = false"
            ></tableList>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MapDraw from "@comp/map/mapDraw/MapDraw";
import baseMap from "./base_map/index";
import changeBtn from "./base_map/change_btn"; //图层控制按钮
import tableList from "./table_list"; //图斑详细列表
import spotDetail from "./spot_detail"; //图斑详情
import legendList from "./legend_list"; //图例
import mapAct from "./map_act"; //地图操作
import animateAct from "./animate_act"; //动画
import Draggabilly from "draggabilly";
export default {
    name: "",
    components: {
        MapDraw,
        baseMap,
        changeBtn,
        tableList,
        spotDetail,
        legendList,
        mapAct,
        animateAct,
    },
    data() {
        return {
            mapData: {
                baseMapNum: 1,
                mapTypeBtn: false,
            },
            type: "",
            left: null,
            right: null,
            leftYear: "2022",
            rightYear: "2021",
            leftLayer: null,
            layerList: [
                {
                    name: "2022年高清影像",
                    year: "2022",
                    childList: [
                        {
                            ID: "99142b34-e84e-45c9-b33d-db27eb23645b",
                            url:
                                "https://jmsjc.jmsclwh.cn/yx/imgsvr/tiles/jms:jms05_2022_w/{z}/{x}/{y}",
                            name: "2022年0.5米分辨率正射影像",
                            LAYER: "jms:jms05_2022_w",
                        },
                        {
                            ID: "9c8434cc-00d2-4628-9922-7ca54294eabe",
                            url:
                                "https://jmsjc.jmsclwh.cn/yx/imgsvr/tiles/jms:jms01_15_2022new_w/{z}/{x}/{y}",
                            name: "2022年0.1米分辨率正射影像15个村落",
                            LAYER: "jms:jms01_15_2022new_w",
                        },
                    ],
                    gqyxGroup: null,
                    group: null, //用户已保存的标绘图层组
                },
                {
                    name: "2021年高清影像",
                    year: "2021",
                    childList: [
                        {
                            ID: "34f59253-832b-4d84-a968-0417480bb693",
                            url:
                                "https://jmsjc.jmsclwh.cn/yx/imgsvr/tiles/jms:jms05_2021_w/{z}/{x}/{y}",
                            name: "2021年0.5米高清影像",
                            LAYER: "jms:jms05_2021_w",
                        },
                        {
                            ID: "90918055-acef-491a-9e75-1ee4b2ddb6eb",
                            url:
                                "https://jmsjc.jmsclwh.cn/yx/imgsvr/tiles/jms:jms01_15_2021_w/{z}/{x}/{y}",
                            name: "2021年0.1米分辨率正射影像15个村落",
                            LAYER: "jms:jms01_15_2021_w",
                        },
                    ],
                    gqyxGroup: null,
                    group: null, //用户已保存的标绘图层组
                },
            ],
            spotListState: false, //是否打开图斑列表
            lddbState: true, //false 显示联动对比两个地图，true 只显示一个地图
            jldbState: false, //卷帘对比
            spotPart: {
                detail: null,
                dialog: false,
            },
            drawConf: null,
            drawnItems: "",
            deleteState: false,
            deleteIdList: [],
            type: null,
        };
    },
    computed: {
        ...mapGetters(["heritageId", "userId"]),
    },
    mounted() {
        this.type = this.$route.query.type ? this.$route.query.type : "";

        let elem = document.querySelector(".draggable");
        new Draggabilly(elem, {
            containment: true,
            handle: ".handle",
        });
        this.initMouse();
        this.drawnItems = new L.FeatureGroup().addTo(this.left);
        this.drawConf = {
            position: "topleft",
            show: true,
            draw: {
                polygon: true,
                polyline: false,
                rectangle: true,
            },
            edit: {
                featureGroup: this.drawnItems,
            },
        };
        // 图层按钮
        this.$refs.changeBtn.initMap(this.left, this.right);
        this.layerList[0].group = L.layerGroup().addTo(this.left);
        this.layerList[1].group = L.layerGroup().addTo(this.right);
        this.layerList[0].gqyxGroup = L.layerGroup().addTo(this.left);
        this.layerList[1].gqyxGroup = L.layerGroup().addTo(this.right);
        // 获取影像选择列表
        this.layerList.map((item) => {
            this.initImageLayer(item);
        });
        // 获取图斑数据
        this.getSpotJson();
    },
    methods: {
        ...mapActions([
            "SensingAdd", // 图斑新建保存
            "GetSensingJson",
            "BatchUpdateSensing",
            "deleteMonitorData",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 全屏操作
        changeFullScreen() {
            this.common.fullScreen();
        },
        // 鼠标 初始化
        initMouse() {
            let myIcon = L.icon({
                iconUrl: require("@image/remote_sensing/mousemove.png"),
            });
            this.leftMouse = L.marker([0, 0]);
            this.leftMouse.setIcon(myIcon);
            this.rightMouse = L.marker([0, 0]);
            this.rightMouse.setIcon(myIcon);
            this.initMouseMove("left");
            this.initMouseMove("right");
        },
        // 鼠标 移动 初始化
        initMouseMove(dom) {
            let _this = this;
            let code = {
                left: "right",
                right: "left",
            };
            let map = this[dom];
            // 鼠标位置改变   另一半地图鼠标位置相应改变
            map.on("mousemove", function(e) {
                _this.isActive = dom;
                _this[dom].removeLayer(_this[dom + "Mouse"]);
                _this[code[dom]].addLayer(_this[code[dom] + "Mouse"]);
                _this[code[dom] + "Mouse"].setLatLng(
                    L.latLng([e.latlng.lat, e.latlng.lng])
                );
            });
            // 同步变化
            map.on("moveend", function(e) {
                if (this._container.id === "mapidbox" + _this.isActive) {
                    let newCenter = this.getCenter();
                    let newZoom = this.getZoom();
                    _this[code[dom]].setView(newCenter, newZoom);
                }
            });
        },
        // 初始化左右两个高清影像
        initImageLayer(obj) {
            obj.childList.map((item1) => {
                let layer = new L.tileLayer(item1.url, {
                    crs: L.CRS.EPSG3857,
                    maxZoom: 22,
                }).addTo(obj.gqyxGroup);
                if (item1.name == "2022年0.5米分辨率正射影像") {
                    this.leftLayer = layer;
                }
            });
        },
        // 获取图斑地图标绘数据
        async getSpotJson() {
            let res = await this.GetSensingJson({
                year1: this.rightYear,
                year2: this.leftYear,
            });
            this.drawnItems.clearLayers();
            let da = res.ResultValue ? res.ResultValue : [];
            this.drawSpotJson(da, this.layerList[0].group, 0);
            this.drawSpotJson(da, this.layerList[1].group, 1);
        },
        // 获取图斑地图标绘数据
        async drawSpotJson(list, group, num) {
            this.clearLayersGroup(group);
            list.map((item, index) => {
                let geo = $.parseJSON(item.GEOJSON);
                if (!geo) return;
                // SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
                let color = item.SHZT === "2" ? "yellow" : "blue";
                let layerData = L.geoJson(geo, {
                    style: () => {
                        return {
                            color: color,
                            renderer: L.svg(),
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        layer.item = item;
                        layer.on("click", () => {
                            if (this.deleteState) {
                                if (item.SHZT == "0") {
                                    this.$message({
                                        type: "warning",
                                        message: "已提交图斑不可删除",
                                    });
                                } else {
                                    this.deleteSpotItem(item);
                                }
                            } else {
                                this.spotPart.detail = item;
                                this.spotPart.dialog = true;
                                this.layerList.map((item1) => {
                                    this.changeGroupItemColor(item1, item);
                                });
                            }
                        });
                        if (layer.feature.geometry.type == "Point") {
                            let iconS1 = L.icon({
                                iconUrl: require("@image/map/icon/markerBasic.png"),
                                iconAnchor: [25, 35],
                            });
                            let iconS2 = L.icon({
                                iconUrl: require("@image/map/icon/imarker2-2.png"),
                                iconAnchor: [25, 35],
                            });
                            if (item.SHZT === "2") {
                                layer.setIcon(iconS1);
                            } else {
                                layer.setIcon(iconS2);
                            }
                        }
                        if (num == 0) {
                            this.drawnItems.addLayer(layer);
                        }
                    },
                });
                layerData.addTo(group);
            });
        },
        // 改变json图斑的颜色
        changeGroupItemColor(obj, item) {
            Object.values(obj.group._layers).forEach((layer) => {
                Object.values(layer._layers).forEach((layer1) => {
                    let color = layer1.item.SHZT === "2" ? "yellow" : "blue";
                    if (
                        item &&
                        layer1.item.ID == item.ID &&
                        layer1.options.color != "#4ba1a5"
                    ) {
                        layer1.setStyle({
                            color: "#4ba1a5",
                        });
                    } else {
                        layer1.setStyle({
                            color: color,
                        });
                    }
                });
            });
        },
        closeSpotDetail() {
            this.spotPart.dialog = false;
            this.spotPart.detail = null;
            this.layerList.map((item) => {
                this.changeGroupItemColor(item);
            });
        },
        // 打开联动对比
        openLddb() {
            this.lddbState = true;
        },
        // 关闭联动对比
        closeLddb() {
            this.lddbState = false;
        },
        // 打开卷帘对比
        openJldb() {
            this.jldbState = true;
        },
        // 关闭卷帘对比
        closeJldb() {
            this.jldbState = false;
        },
        changeOperate(ind) {
            this.operindex = ind;
        },
        // 图斑列表的显示与隐藏
        showTbList() {
            this.$router.push({
                name: "remoteTable",
                params: {
                    ItemID: "90305",
                    MC: "遥感专项监测",
                },
            });
        },
        // 图斑修改
        updateDraw(type, event) {
            switch (type) {
                case "created": // 新建
                    this.operateCreated(event);
                    break;
                case "deletestart": // 删除开始
                    this.deleteIdList = [];
                    this.deleteState = true;
                    break;
                case "deleted": // 删除完成
                    this.deleteSpotAll();
                    break;
                case "edited": // 编辑
                    this.operateEdit(event);
                    break;
            }
        },
        // 新建图斑
        async operateCreated(event) {
            let formData = {
                GEOJSON: null,
                YEAR1: null,
                YEAR2: null,
                TBBH: null,
                TBNAME: null,
                TBDESC: null,
                MJ: null,
                TDFL: null,
                TDFLBHH: null,
                PG: null,
                SJWHYCBM: null,
                ZTGJSFBH: null,
                SFXJSJXM: null,
                SDLX: null,
                XMMC: null,
                JZYT: null,
                XMJSZT: null,
                SFZDWWBMTY: null,
                KGSJ: null,
                JGSJ: null,
                JZMJ: null,
                GD: null,
                CS: null,
                SFFHGLGD: null,
                SHZT: "1",
            };
            let json = JSON.stringify(event.layer.toGeoJSON());
            let mj =
                event.layerType === "marker"
                    ? 0
                    : L.GeometryUtil.geodesicArea(
                          event.layer.getLatLngs()[0]
                      ).toFixed(2);
            formData.RWID = this.$route.query.RWID;
            formData.SJWHYCBM = this.heritageId;
            formData.YEAR1 = this.leftYear;
            formData.YEAR2 = this.rightYear;
            formData.GEOJSON = json;
            formData.MJ = mj;
            let ent = formData; // 图斑表信息
            let res = await this.SensingAdd({
                Geojson: json,
                ent,
                fjids: "",
            });
            if (res && res != null && res !== "" && res !== undefined) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.getSpotJson();
            }
        },
        async deleteSpotItem(obj) {
            this.deleteIdList.push(obj.ID);
            this.layerList.map((item) => {
                Object.values(item.group._layers).forEach((layer) => {
                    Object.values(layer._layers).forEach((layer1) => {
                        if (layer1.item.ID == obj.ID) {
                            item.group.removeLayer(layer);
                        }
                    });
                });
            });
        },
        async deleteSpotAll() {
            let res = await this.deleteMonitorData({
                ids: this.deleteIdList.join(","),
                itemid: 90305,
            });
            if (res.IsSuccess) {
                this.getSpotJson();
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
        },
        async operateEdit(event) {
            let changeSpotList = [];
            Object.values(event.layers._layers).forEach((layer) => {
                let obj = {
                    Id: layer.item.ID,
                    GeoJson: JSON.stringify(layer.toGeoJSON()),
                    MJ: L.GeometryUtil.geodesicArea(
                        layer.getLatLngs()[0]
                    ).toFixed(2),
                };
                changeSpotList.push(obj);
            });
            let res = await this.BatchUpdateSensing({
                jsonstr: JSON.stringify(changeSpotList),
            });
            if (res.IsSuccess) {
                this.getSpotJson();
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
        },

        selectLayer(item) {
            this.spotListState = false;
            this.spotPart.detail = item;
            this.spotPart.dialog = true;
            this.layerList.map((item1) => {
                this.changeGroupItemColor(item1, item);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.remote-module {
    width: 100%;
    height: 100%;
    position: relative;
    .remote-map {
        width: 100%;
        height: 100%;
        .left-part {
            height: 100%;
            position: relative;

            .layer-part {
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 10;
            }
            .animate-part {
                position: absolute;
                right: 186px;
                top: 10px;
                z-index: 10;
            }
            .roller-shutter {
                position: absolute;
                z-index: 10;
                top: 50%;
                font-size: 30px;
                font-weight: bolder;
                color: red;
            }
            .btn-back {
                position: absolute;
                right: 95px;
                top: 10px;
                z-index: 10;
                height: 32px;
            }
        }
        .right-part {
            width: 50%;
            height: 100%;
            transition: all 1s;
        }
        .mapOperate {
            position: absolute;
            top: 340px;
            left: 10px;
            z-index: 1000;
            margin-right: 18px;
        }
        .select-left {
            position: absolute;
            top: 10px;
            left: 55px;
            z-index: 10;
        }
        .select-right {
            position: absolute;
            top: 10px;
            left: calc(50% + 20px);
            z-index: 10;
        }
        .spot-module {
            position: absolute;
            left: 6%;
            top: 84px;
            width: 820px;
            z-index: 20;
            background: #fff;
            border-radius: 4px;
            h5 {
                color: #fff;
                background: #4ba1a5;
                border-bottom: 1px solid #4ba1a5;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                padding: 10px 20px;
                text-align: left;
                color: white;
                span {
                    float: right;
                    cursor: pointer;
                    line-height: 21px;
                    font-size: 18px;
                    &:hover {
                        color: #ea6052;
                    }
                }
            }
        }
    }
    // 图例
    .legend-part {
        position: absolute;
        left: 20px;
        bottom: 10px;
        min-height: 56px;
        max-height: 100px;
        z-index: 10;
    }
    .tb-list {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 20px;
    }
}
</style>
