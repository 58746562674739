<template>
    <div class="map">
        <div :id="'mapidbox' + mapid" class="myMap"></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "map_tdt",
    props: {
        mapData: Object,
        // 地图id 默认随机数, 遥感监测需指定id
        mapid: {
            type: String,
            default: () => String(Math.random()),
        },
    },
    data() {
        return {
            mapInfo: {
                mode: "fix", //fix 固定模式  fly 飞行模式  fit 缩放模式
                lng: window.mapCenter[1],
                lat: window.mapCenter[0],
                zoom: 13, //放大级别
                minZoom: 3, //缩放 最小
                maxZoom: 22, //缩放 最大
                zoomDelta: 0.25, //缩放层级
                fitBounds: window.mapFitBounds, //最适合大小展开
                baseMap: true, //是否加载底图
                baseMapNum: 0, //0地图 1影像 2地形
                mapTypeBtn: true, //是否展示地图切换按钮
                dragging: true, //拖拽
            },
            basicMap: "", //地图
            mapType: "", // 地图 影像 地形
            vectorLayer: null, //地图
            imgLayer: null, //影像
            terLayer: null, //地形
            vectorTagLayer: null,
            imgTagLayer: null,
            terTagLayer: null,
        };
    },
    mounted() {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
        this.initMap();
    },
    components: {},
    watch: {},
    methods: {
        ...mapActions(["getVectorStyle", "GetRcxcXcpq"]),
        initMap() {
            this.mapInfo = { ...this.mapInfo, ...this.mapData };
            let id = `mapidbox${this.mapid}`;
            this.basicMap = window.L.map(id, {
                crs: window.L.CRS.EPSG3857,
                editable: true,
                zoomControl: false, //左上角放大 缩小
                zoom: this.mapInfo.zoom,
                minZoom: this.mapInfo.minZoom,
                maxZoom: this.mapInfo.maxZoom,
                zoomSnap: 0.25,
                zoomDelta: this.mapInfo.zoomDelta,
                dragging: this.mapInfo.dragging,
                attributionControl: false, //右下角 加载底图 产生的 链接 隐藏掉
            });

            if (this.mapInfo.mapTypeBtn) {
                this.initControls();
            }
            // 地图模式
            switch (this.mapInfo.mode) {
                case "fix": // 固定模式
                    this.basicMap.setView(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fly": // 飞行模式
                    this.basicMap.flyTo(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fit": // 缩放模式
                    this.basicMap.fitBounds([this.mapInfo.fitBounds]);
                    break;
            }

            // 加载底图
            this.loadBaseLayer();
            this.$emit("update:myMap", this.basicMap);
        },
        //右下角的 审图号
        initControls() {
            let mousePositionControl = window.L.control.mousePosition({
                position: "bottomright",
                separator: ",",
                emptystring: " ",
                lngFirst: true,
                numDigits: 5,
                prefix: "",
                showZoom: true,
            });
            mousePositionControl.addTo(this.basicMap); //显示鼠标位置的地理坐标

            //没有找到获取控件的方法，暂用此简单暴力不优雅的方式解决
            this.basicMap.on("zoom", (ev) => {
                // eslint-disable-next-line
                let mouseposition = $(".leaflet-control-mouseposition");
                // let mouseInfo = mouseposition.html();
                let lat = ev.target._lastCenter.lat.toFixed(2);
                let lng = ev.target._lastCenter.lng.toFixed(2);
                let zoomInfo =
                    "<span class='MapNumber' style='background:#ddd;'>审图号：GS(2018)1432号&nbsp</span>地图显示级别:" +
                    (this.basicMap.getZoom() + 1) +
                    "级&nbsp&nbsp 经度:" +
                    lng +
                    "，纬度:" +
                    lat;
                mouseposition.html(zoomInfo);
            });
        },
        // 加载底图 影像
        loadBaseLayer() {
            this.imgLayer = this.getWmtsLayer("img", 0); // 影像地图
            this.imgTagLayer = this.getWmtsLayer("cia", 1); // 影像地图注记
            this.basicMap.addLayer(this.imgLayer);
            this.basicMap.addLayer(this.imgTagLayer);
        },
        // 加载WMTS图层
        getWmtsLayer(layerName, num) {
            let url = `https://t{s}.tianditu.gov.cn/${layerName}_w/wmts`;
            let layer = new window.L.TileLayer.WMTS(url, {
                tk: window.tk,
                zIndex: num == 0 ? 0 : 10,
                tileSize: 256,
                crs: window.L.CRS.EPSG3857,
                layer: layerName,
                style: "default",
                tilematrixSet: "w",
                format: "tiles",
                subdomains: "01234567",
                minZoom: 3,
                maxZoom: 17,
                isBaseLayer: true,
            });
            return layer;
        },
    },
};
</script>

<style scoped lang="scss">
.map {
    position: relative;
    height: 100%;
    width: 100%;
    .myMap {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .resetIconPart {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1000;
        .resetIconItem {
            margin-left: 10px;
            .img {
                width: 32px;
                height: 32px;
            }
        }

        .layerCtn {
            margin-top: 10px;
            width: 160px;
            border-radius: 4px;
            box-shadow: 0px 0px 6px 0px #a7a7a7;
            background: #fff;
            position: relative;
        }
    }
}
</style>
