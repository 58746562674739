<template>
    <div class="resetIconPart">
        <div class="btnPart flex-end">
            <div
                v-for="(item, index) in actBtnList"
                :key="index"
                class="resetIconItem"
            >
                <img
                    v-if="item.state"
                    :src="item.src"
                    class="img pointer"
                    @click="getActBtn(item)"
                    :title="item.name"
                />
            </div>
        </div>
        <div class="layerCtn" v-if="actBtnList[1].layerState">
            <layerList
                :listAry="actBtnList[1].listAry"
                keyValue="map"
                @switchStateChange="switchStateChange"
            ></layerList>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList";
import layerList from "./layer_list.vue"; //图层

export default {
    name: "map_tdt",
    props: {},
    data() {
        return {
            mapInfo: {
                mode: "fix", //fix 固定模式  fly 飞行模式  fit 缩放模式
                lng: window.mapCenter[1],
                lat: window.mapCenter[0],
                zoom: 13, //放大级别
                minZoom: 3, //缩放 最小
                maxZoom: 20, //缩放 最大
                zoomDelta: 0.25, //缩放层级
                fitBounds: window.mapFitBounds, //最适合大小展开
                baseMap: true, //是否加载底图
                baseMapNum: 0, //0地图 1影像 2地形
                mapTypeBtn: true, //是否展示地图切换按钮
                dragging: true, //拖拽
            },
            leftMap: null,
            rightMap: null,
            derectionData,
            markerPopup: null,
            xcqList: [], //巡查区列表
            xcqLeftLabelGroup: null, //巡查区Label
            xcqRightLabelGroup: null, //巡查区Label
            actBtnList: [
                {
                    name: "复位",
                    src: require("@image/bigScreen/icon/resetLight.png"),
                    state: true,
                },
                {
                    name: "图层",
                    src: require("@image/bigScreen/icon/layerBtn.png"),
                    layerState: false,
                    state: true,
                    listAry: [
                        {
                            name: "遗产区缓冲区",
                            existState: false,
                            state: true,
                            leftGroup: null,
                            rightGroup: null,
                        },
                        {
                            name: "地形图",
                            existState: false,
                            state: true,
                            leftGroup: null,
                            rightGroup: null,
                        },
                        {
                            name: "巡查片区",
                            existState: false,
                            state: true,
                            leftGroup: null,
                            rightGroup: null,
                        },
                        {
                            name: "土地利用现状图",
                            existState: true,
                            state: false,
                            leftGroup: null,
                            rightGroup: null,
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    components: {
        layerList,
    },
    watch: {},
    methods: {
        ...mapActions(["getVectorStyle", "GetRcxcXcpq"]),
        initMap(left, right) {
            this.leftMap = left;
            this.rightMap = right;
            this.xcqLeftLabelGroup = window.L.layerGroup().addTo(this.leftMap);
            this.xcqRightLabelGroup = window.L.layerGroup().addTo(
                this.rightMap
            );
            this.actBtnList.map((item) => {
                if (item.name == "图层") {
                    item.listAry.map((item1) => {
                        item1.leftGroup = window.L.layerGroup().addTo(
                            this.leftMap
                        );
                        item1.rightGroup = window.L.layerGroup().addTo(
                            this.rightMap
                        );
                    });
                }
            });
            this.leftMap.on("zoomend", (e) => {
                let level = e.target._zoom;
                if (level >= 14.5) {
                    // 巡查区
                    this.drawXcqLabel(this.xcqLeftLabelGroup, this.xcqList);
                    this.drawXcqLabel(this.xcqRightLabelGroup, this.xcqList);
                } else {
                    this.clearLayersGroup(this.xcqLeftLabelGroup);
                    this.clearLayersGroup(this.xcqRightLabelGroup);
                }
            });
            // this.leftMap.on("click", function(ev) {
            //     console.log(ev.latlng.lng, ev.latlng.lat);
            // });
            // this.leftMap.on("zoom", function(ev) {
            //     console.log("放大等级:", ev.target._zoom);
            // });

            // 显示 显隐图层按钮
            this.actBtnList.map((item) => {
                if (item.name == "图层") {
                    item.listAry.map((item1) => {
                        switch (item1.name) {
                            case "遗产区缓冲区":
                                this.loadYcqHcq(item1);
                                break;
                            case "地形图":
                                this.loadDxtMap(item1);
                                break;
                            case "巡查片区":
                                this.getAreaInfo(item1);
                                break;
                            case "土地利用现状图":
                                // this.getTdlyxztInfo(item1);
                                break;
                        }
                    });
                }
            });
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 遗产区缓冲区
        async loadYcqHcq(obj) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_DXT,
            });
            let list = [];
            // 去掉背景色
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] == "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            let layerMapboxLeft = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            });
            let layerMapboxRight = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            });

            layerMapboxLeft.addTo(obj.leftGroup);
            layerMapboxRight.addTo(obj.rightGroup);
            this.common.showVectorLayers();
            let mapboxMapLeft = layerMapboxLeft.getMapboxMap();
            let mapboxMapRight = layerMapboxRight.getMapboxMap();
            mapboxMapLeft.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMapLeft.getContainer();
                    myContainer.style.zIndex = 10;
                });
            });
            mapboxMapRight.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMapRight.getContainer();
                    myContainer.style.zIndex = 10;
                });
            });
        },
        // 地形图
        async loadDxtMap(obj) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_DXT,
            });
            let list = [];
            // 去掉背景色
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] != "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            let layerMapboxLeft = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            });
            let layerMapboxRight = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            });
            layerMapboxLeft.addTo(obj.leftGroup);
            layerMapboxRight.addTo(obj.rightGroup);
            this.common.showVectorLayers();
        },
        //获取片区数据
        async getAreaInfo(obj) {
            let result = await this.GetRcxcXcpq({});
            result.map((item) => {
                let areaItem = JSON.parse(item.QYZB);
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        if (itemInner.JD) {
                            Object.assign(item, itemInner);
                        } else {
                            let center = window.turf.centerOfMass(areaItem);
                            itemInner.JD = center.geometry.coordinates[0];
                            itemInner.WD = center.geometry.coordinates[1];
                            Object.assign(item, itemInner);
                        }
                    }
                });
            });
            this.xcqList = result;
            this.drawXcq(obj, this.xcqList);
        },
        // 土地利用现状图
        async getTdlyxztInfo(obj) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            let list = [];
            layerStyle.layers.map((item) => {
                if (item["source-layer"] == "土地利用") {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            let layerMapboxLeft = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(obj.leftGroup);
            let layerMapboxRight = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(obj.rightGroup);
            this.common.showVectorLayers();
            let mapboxMapLeft = layerMapboxLeft.getMapboxMap();
            mapboxMapLeft.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMapLeft.getContainer();
                    myContainer.style.zIndex = 2;
                });
            });

            let mapboxMapRight = layerMapboxRight.getMapboxMap();
            mapboxMapRight.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = mapboxMapRight.getContainer();
                    myContainer.style.zIndex = 2;
                });
            });
        },
        // 画 巡查区
        drawXcq(obj, list) {
            list.map((item, index) => {
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layerLeft = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                });
                let layerRight = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                });
                layerLeft.addTo(obj.leftGroup);
                layerRight.addTo(obj.rightGroup);
                // layer.on("mouseover", () => {
                //     let lng = 0;
                //     let lat = 0;
                //     let markerList = [];
                //     if (item.JD) {
                //         lng = item.JD;
                //         lat = item.WD;
                //     } else {
                //         if (areaItem.geometry.type == "MultiPolygon") {
                //             areaItem.geometry.coordinates.map((item) => {
                //                 item.map((itemInner) => {
                //                     markerList = [...itemInner, ...markerList];
                //                 });
                //             });
                //         } else {
                //             markerList = areaItem.geometry.coordinates[0];
                //         }
                //         let result = this.common.getPolygonCenter(markerList);
                //         lng = result[0];
                //         lat = result[1];
                //     }
                //     this.markerPopup
                //         .setLatLng([lat, lng])
                //         .setContent(`<div class="tip">${item.QYMC}</div>`)
                //         .openOn(this.basicMap);
                // });
                // layer.on("mouseout", () => {
                //     this.markerPopup.removeFrom(this.basicMap);
                // });
                // layer.on("click", (event) => {
                // let latlng = event.latlng;
                // let value =
                //     latlng.lng.toFixed(8) + "," + latlng.lat.toFixed(8);
                // this.$emit("update:coordinate", value);
                // this.$emit("areaItem", item);
                // });
            });
        },
        drawXcqLabel(group, list) {
            this.clearLayersGroup(group);
            list.map((item, index) => {
                let width = 12 * item.QYMC.length;
                let icon = window.L.divIcon({
                    html: `<div style="width:${width}px;height:16px;white-space:nowrap;color:#000;font-size:12px;font-weight:600;">${item.QYMC}</div>`,
                    className: "",
                    iconSize: [width, 16],
                    iconAnchor: [width / 2, 8],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                }).addTo(group);
            });
        },
        // 按钮操作
        getActBtn(item) {
            switch (item.name) {
                case "复位":
                    this.leftMap.setView(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    this.rightMap.setView(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "图层":
                    item.layerState = !item.layerState;
                    break;
            }
        },
        // 更改图层
        switchStateChange(state, item) {
            item.state = state;
            if (!state) {
                this.clearLayersGroup(item.leftGroup);
                this.clearLayersGroup(item.rightGroup);
                return;
            }
            switch (item.name) {
                case "遗产区缓冲区":
                    this.loadYcqHcq(item);
                    break;
                case "地形图":
                    this.loadDxtMap(item);
                    break;
                case "巡查片区":
                    this.getAreaInfo(item, this.xcqList);
                    break;
                case "土地利用现状图":
                    this.getTdlyxztInfo(item);
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.resetIconPart {
    .resetIconItem {
        margin-left: 10px;
        .img {
            width: 32px;
            height: 32px;
        }
    }

    .layerCtn {
        margin-top: 10px;
        width: 160px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px #a7a7a7;
        background: #fff;
        position: relative;
    }
}
</style>
