<template>
    <div class="basic-upload-part">
        <!-- 单个上传文件请控制在{{ maxSize }}M以内,上传格式限于：{{ uploadFormart[basicType].join(',') }} -->
        <el-upload
            :class="{
                disableShow: limit <= fileList.length ? true : false,
                dragUpload: listType === 'drag' ? true : false,
            }"
            :accept="'.' + uploadFormart[basicType].join(',.')"
            :action="`${BASE_URL}UpLoad/FileSave?LJ=${savePath}`"
            :multiple="limit != 1"
            :limit="limit"
            :list-type="listType"
            :file-list="fileList"
            :disabled="disabled || disabledProgress"
            :before-upload="beforeUpload"
            :on-success="successUpload"
            :on-error="errorUpload"
            :on-progress="progressUpload"
            :on-preview="handlePreview"
            :on-remove="removeFile"
            :on-exceed="handleExceed"
            :drag="listType == 'drag' ? true : false"
            style="width: 100%; height: 100%"
        >
            <!--  :title="tipText" -->
            <div
                class="flex-y-center"
                :class="btnStyle"
                v-if="listType == 'text'"
            >
                点击上传
            </div>
            <div v-if="listType == 'picture-card'">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file, fileList }">
                    <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-preview"
                            @click="handlePreview(file)"
                        >
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                            class="el-upload-list__item-delete"
                            @click="removeFile(file, fileList)"
                        >
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                </div>
            </div>
            <!-- <div
                v-if="listType == 'drag'"
                class="drag-part flex-both-center flex-column"
            >
                <img src="@image/icon/common/upFile.png" class="upFile" />
                <div class="el-upload__text">点击或将文件拖到这里上传数据</div>
                <div class="el-upload__tip" slot="tip">
                    提示：仅支持 {{ uploadFormart[basicType].join(",") }} 格式
                </div>
            </div> -->
        </el-upload>
        <div
            class="tip-text"
            :style="{ color: tipPart.color }"
            v-if="tipPart.state"
        >
            {{ tipText }}
        </div>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            fileList: [],
            imgList: [],
            uploadFormart: {
                xls: ["xls", "xlsx"],
                txt: ["txt"],
                documentSpecial: ["pdf", "doc", "docx"],
                document: [
                    "pdf",
                    "doc",
                    "docx",
                    "xls",
                    "xlsx",
                    "txt",
                    "zip",
                    "rar",
                ],
                picture: ["gif", "jpg", "jpeg", "bmp", "png", "tiff"],
                compressed: ["zip", "rar"],
                demoImport: ["zip", "xlsx"], //新建项目文物局 数据导入
            },
            urlList: [],
            percentage: 0,
            tipText: "",
            tipPart: {
                state: false,
                labelState: true,
                maxState: true,
                color: "#79baf7",
            },
            disabledProgress: false, //上传100% 才可以上传第二个
        };
    },
    props: {
        savePath: {
            type: String,
            default: "WWQZSP",
        },
        limit: {
            type: Number,
            default: 1,
        },
        listType: {
            type: String,
            default: "picture-card", //text 文字  picture-card 图形
        },
        basicType: {
            type: String,
            default: "picture", //document 文件  picture 图片
        },
        // 最大上传文件大小 单位：M(兆)
        maxSize: {
            type: Number,
            default: 1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tipOption: {
            type: Object,
            default() {
                return {
                    state: true,
                    color: "#606266",
                };
            },
        },
        btnStyle: {
            type: String,
            default() {
                return "btn-1";
            },
        },
        fileExist: {
            type: Object,
            default() {
                return {
                    list: [],
                    name: "WDMC",
                    url: "CCLJ",
                };
            },
        },
    },
    watch: {
        fileExist: {
            deep: true,
            immediate: true,
            handler() {
                if (this.fileExist.list.length) {
                    this.fileList = this.fileExist.list.map((item) => {
                        return {
                            name: item[this.fileExist.name],
                            url: item[this.fileExist.url],
                            FilePath: item[this.fileExist.url],
                            Guid: item.ID,
                            // name: item.WDMC || item.FileName,
                            // url: item.CCLJ
                        };
                    });
                } else {
                    this.fileList = [];
                }
            },
        },
    },
    computed: {},
    mounted() {
        // 深度合并对象
        this.tipPart = this.common.deepMerge(this.tipPart, this.tipOption);
        if (this.tipPart.labelState) {
            this.tipText += `最多上传${this.limit}个文件，`;
        }
        if (this.tipPart.maxState) {
            this.tipText += `单个上传文件请控制在${this.maxSize}M以内,`;
        }

        this.tipText += `上传格式限于：${this.uploadFormart[
            this.basicType
        ].join(",")}`;
    },
    methods: {
        ...mapMutations({}),
        // 点击修改按钮 显示图片
        getExistImg(url) {
            this.fileList = [];
            this.urlList = [];
            if (url) {
                url.split(",").map(() => {
                    let obj = {
                        name: url,
                        url: this.mediaUrl + url,
                        route: url,
                    };
                    this.fileList.push(obj);
                    this.urlList.push(this.url);
                });
            }
        },
        errorUpload(error, file, fileList) {
            this.percentage = 0;
            this.$message({
                message: "上传失败",
                type: "error",
            });
        },
        handleExceed(files, fileList) {
            // this.$message.warning(
            //     `当前限制选择 ${this.limit} 个文件，本次选择了 ${
            //         files.length
            //     } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            // )
            this.$message.warning(
                `已上传 ${this.limit} 个文件，最多只能上传 ${this.limit}} 个文件！`
            );
        },
        progressUpload(e, file, fileList) {
            this.percentage = Math.floor(file.percentage);
        },
        // 文件预览
        handlePreview(file) {
            window.open(file.FilePath);
        },
        // 添加文件 问题图片
        beforeUpload(file) {
            if (this.maxSize && file.size > this.maxSize * 1000000) {
                this.$message({
                    message: `请勿上传大于${this.maxSize}M的文件`,
                    type: "warning",
                });
                return false;
            }
            let type = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (!this.uploadFormart[this.basicType].includes(type)) {
                this.$message({
                    message: `上传格式有误`,
                    type: "warning",
                });
                return false;
            }
            this.disabledProgress = true;
        },
        // 上传成功
        successUpload(response, file, fileList) {
            this.percentage = 0;
            this.disabledProgress = false;
            response.ResultValue.map((item) => {
                item.name = item.FileName;
                item.url = item.FilePath;
            });
            this.fileList = [...this.fileList, ...response.ResultValue];
            this.$emit("getFileList", this.fileList);
        },
        //删除图片
        removeFile(file, fileList) {
            this.fileList = fileList;
            if (this.fileExist.list.length) {
                this.$emit("delFileItem", file, fileList);
            } else {
                this.$emit("getFileList", this.fileList);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.basic-upload-part {
    width: 100%;
    height: 100%;
    position: relative;
    :deep(.el-upload--text) {
        width: 100%;
    }

    .btn-1 {
        width: 100%;
        height: 28px;
        color: #77b1d2;
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
    }

    .btn-2 {
        width: 100px;
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        border-radius: 3px;
        background: #186d70;
        color: #fff;
        justify-content: center;
    }
    .btn-3 {
        width: 100px;
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        border-radius: 3px;
        background: #6e8492;
        color: #fff;
        justify-content: center;
    }
    .tip-text {
        margin-top: 10px;
        width: 100%;
        font-size: 14px;
    }

    .drag-part {
        width: 100%;
        height: 100%;
        .upFile {
            width: 60px;
            height: 44px;
        }
        .el-upload__text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #364954;
            margin-top: 24px;
        }
        .el-upload__tip {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aac1d0;
        }
    }
}
</style>
