<template>
    <div class="table-part">
        <h3 class="handle">图斑列表</h3>
        <sn-button
            class="tb_button"
            :snButton="snButton"
            @handleChange="handleChange"
        ></sn-button>
        <div class="box_table">
            <el-table
                id="tb_table"
                class="scrollbar"
                :data="tbList"
                height="100%"
                highlight-current-row
                @row-click="selectLayer"
            >
                <el-table-column prop="TBBH" label="图斑编号"></el-table-column>
                <el-table-column
                    prop="TBNAME"
                    label="图斑名称"
                    width="110"
                ></el-table-column>
                <el-table-column label="对比年份" width="100">
                    <template slot-scope="{ row }">
                        <span>{{ row.YEAR1 }}--{{ row.YEAR2 }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="TDFL"
                    label="土地类型（变化前）"
                    width="100"
                ></el-table-column>
                <el-table-column
                    prop="TDFLBHH"
                    label="土地类型（变化后）"
                    width="100"
                ></el-table-column>
                <el-table-column
                    prop="SDLX"
                    label="新建涉建项目"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="XMMC"
                    label="项目名称"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="JZYT"
                    label="建筑用途"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="XMJSZT"
                    label="项目建设状态"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="SFZDWWBMTY"
                    label="是否征得文物部门同意"
                    width="110"
                ></el-table-column>
                <el-table-column
                    prop="KGSJ"
                    label="开工时间"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="JGSJ"
                    label="竣工时间"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="JZMJ"
                    label="建筑面积"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="GD"
                    label="高度"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="CS"
                    label="层数"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="SFFHGLGD"
                    label="是否符合管理规定"
                    width="140"
                ></el-table-column>
                <el-table-column prop="PG" label="评估" width="120">
                    <template slot-scope="{ row }">
                        <span>{{ getValue(row.PG) }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            v-show="total"
            layout="total, prev, pager, next"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="10"
            @current-change="getSpotList"
        ></el-pagination>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "remote_sensing",
    components: {},
    data() {
        return {
            snButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "returnMap",
                        name: "返回",
                        round: true,
                        backColor: "#ff9066",
                        color: "#fff",
                    },
                ],
            },
            currentPage: 1,
            tbList: [],
            total: 0,
            TBPGs: [
                {
                    VALUE: "正面影响",
                    TYPE: "0",
                    COLOR: "#A7C49B",
                },
                {
                    VALUE: "兼有正、负影响",
                    TYPE: "2",
                    COLOR: "#D7C49D",
                },
                {
                    VALUE: "负面影响",
                    TYPE: "1",
                    COLOR: "#CD7177",
                },
                {
                    VALUE: "目前尚未确定",
                    TYPE: "3",
                    COLOR: "#B09298",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["heritageId", "userId"]),
    },
    mounted() {
        this.getSpotList();
    },
    methods: {
        ...mapActions(["GetSensingList"]),
        // 获取图斑列表
        async getSpotList() {
            let res = await this.GetSensingList({
                sjwhycbm: this.heritageId,
                startYear: "",
                endYear: "",
                bhgqh: "",
                xhzh: "",
                pageIndex: this.currentPage,
                pageSize: 10,
            });
            this.tbList = res.ResultValue.Data;
            this.total = res.ResultValue.Total;
        },
        selectLayer(row) {
            this.$emit("selectLayer", row);
        },
        handleChange() {
            this.$emit("close");
        },
        getValue(val) {
            let data = this.TBPGs.filter((item) => item.TYPE === val);
            if (data[0]) {
                return data[0].VALUE;
            }
        },
    },
};
</script>
<style>
.table-part .upload-demo .el-upload-list {
    display: none;
}
</style>
<style lang="scss" scoped>
.table-part {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
    h3 {
        font-size: 14px;
        line-height: 50px;
        border: 0;
        margin: 0;
        padding: 0;
        font-family: Microsoft yahei, Arial;
        color: #48576a;
        border-collapse: collapse;
        list-style: none;
        background: white;
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
    }
    .el-table .cell,
    .el-table th > div {
        padding-left: 0px;
        padding-right: 0px;
    }
    .el-table__header .cell {
        font-weight: normal;
    }
    .box_table {
        margin-top: 20px;
        height: calc(100% - 90px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    .tb_button {
        position: absolute;
        right: 30px;
        top: 25px;
    }
    .el-pagination {
        text-align: left;
        margin-top: 5px;
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
        background: none;
    }
}
.el-upload-list__item-name:visited {
    color: #606266;
}
</style>
