<template>
    <div class="legend-module flex scrollbar-hidden">
        <div class="title flex-y-center">图例</div>
        <div class="flex flex-1 list flex-wrap">
            <div
                v-for="(item, index) in legendList"
                :key="index"
                class="flex-y-center list-item"
            >
                <div class="circle" :style="{ background: item.color }"></div>
                <div class="name">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    data() {
        return {
            legendList: [
                {
                    name: "其他值",
                    color: "#000000",
                },
                {
                    name: "耕地",
                    color: "rgb(90,100,115)",
                },
                {
                    name: "林地",
                    color: "rgb(42,54,78)",
                },
                {
                    name: "台地茶园",
                    color: "rgb(60,80,95)",
                },
                {
                    name: "台地",
                    color: "rgb(80,120,120)",
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
.legend-module {
    .title {
        width: 20px;
        min-height: 56px;
        max-height: 100px;
        background: #2e7679;
        font-weight: 400;
        font-size: 12px;
        color: #d8eaf6;
        text-align: center;
        padding-top: 5px;
    }
    .list {
        padding-left: 10px;
        background: #226265;
        .list-item {
            margin-right: 10px;
            .circle {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .name {
                font-weight: 400;
                font-size: 12px;
                color: #d8eaf6;
            }
        }
    }
}
</style>
